import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Container = styled.div`
  ${tw``}
`
const Wrapper = styled.div`
  ${tw`max-w-[1440px] mx-auto py-14 px-7 xl:px-28`}
`

const Headline = styled.div`
  ${tw`font-black text-gray-800 uppercase font-primary md:text-80 text-46 max-w-[1220px] w-full pb-14 sm:px-4`}
`

const Links = styled.div`
  ${tw`flex flex-col items-center justify-between gap-10 sm:flex-row sm:px-4`}
`

const WpAcfAwardsModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfModuleAwards
  return (
    <Container style={{ backgroundColor: blockData.backgroundColor }}>
      <Wrapper>
        {blockData.headline !== null && <Headline style={{ color: blockData.color }}>{parse(blockData.headline)}</Headline>}
        <Links style={{ justifyContent: blockData.links.length === 3 ? "space-between" : "flex-start" }}>
          {blockData.links?.map(item => {
            const imageElement = <GatsbyImage style={{ height: "auto", maxWidth: "250px" }} image={getImage(item.image.localFile.childImageSharp)} alt="" />

            return item.link?.url ? (
              <Link to={item.link.url} key={item.id}>
                {imageElement}
              </Link>
            ) : (
              <div key={item.id}>{imageElement}</div>
            )
          })}
        </Links>
      </Wrapper>
    </Container>
  )
}

WpAcfAwardsModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfAwardsModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfAwardsModuleBlock
