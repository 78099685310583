import { Link, graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"
import PropTypes from "prop-types"
import Button from "@elements/button"
import React from "react"
import parse from "html-react-parser"
import { motion } from "framer-motion"
import stopperClick from "../../images/stopper-click.png"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

const CaseGridContainer = styled.div`
  ${tw`grid grid-cols-none gap-2 pb-2 overflow-hidden bg-white md:grid-cols-4`}
`
const Card = styled(motion.div)`
  ${tw`relative overflow-hidden cursor-pointer `}
`
const CardImage = styled.div`
  ${tw`relative flex `}
  & .gatsby-image-wrapper {
    transition-timing-function: cubic-bezier(0.6, 0.01, 0.05, 0.9);
    ${tw`h-full transition-all duration-500 transform scale-100`}
  }
  &:hover .gatsby-image-wrapper {
    ${tw`scale-125`}
  }
`
const CardHeadline = styled.div`
  ${tw`relative flex flex-col w-full px-6 py-4 font-black uppercase lg:py-8 md:py-4 sm:py-8 xl:px-20 lg:px-16 md:px-12 text-yellow font-primary`}
  z-index: 1;
`

const CardHeadlineSticker = styled.span`
  ${tw`absolute w-16 2xl:w-40 md:w-20 2xl:-top-24 2xl:-right-24 sm:-top-12 sm:-right-12 -top-12 -right-6`}
`

const ShowMoreCard = styled.div`
  ${tw`flex flex-col justify-center col-span-4 px-6 py-12 font-black text-black bg-yellow md:px-5 md:py-5 md:col-span-2`}
`
const ShowMoreCardHeadline = styled.div`
  ${tw`uppercase text-46 md:text-32 lg:text-60 2xl:text-80`}
`
const BannerIframeWrap = styled.div`
  ${tw`absolute w-full h-full pointer-events-none `}
`

const WpAcfCasesSectionBlock = ({ moduleData }) => {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 28 }) {
        id
        title
        acfHomeSectionCases {
          selectedCases {
            ... on WpCase {
              acfCasePostTypeTeaserFields {
                teaserHeadline
                teaserSubline
                headlineColor
                headlineHorizontalAlign
                headlineVerticalAlign
                teaserSticker {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800)
                    }
                  }
                }
                teaserImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 2000)
                    }
                  }
                }
              }
              id
              title
              slug
              databaseId
              uri
              excerpt
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 2000)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const stopperAni = {
    init: { opacity: 0, rotate: 20, ease: "easeInOut", duration: 0.3 },
    ani: {
      opacity: 1,
      rotate: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  }

  return (
    <section
      key="caseSection"
      id={`${moduleData.attributes?.anchor || "cases-section"}`}
    >
      <CaseGridContainer>
        {data.wpPage.acfHomeSectionCases.selectedCases.map((item, i) => {
          return [
            <Card
              key={item.databaseId}
              className={`case-card ${
                i === 2
                  ? `col-span-4 md:col-span-4`
                  : `col-span-4 md:col-span-2`
              }`}
              layoutId={item.slug}
              initial="init"
              whileHover="ani"
              animate="init"
            >
              <Link to={`${item.uri}`}>
                <CardImage
                  className={
                    i === 2
                      ? `h-60vw md:h-50vw fhd:max-h-[1000px]`
                      : "h-50vw md:h-25vw"
                  }
                >
                  {/* {i !== 2 && ( */}
                  <div className={`absolute w-full h-full block`}>
                    <GatsbyImage
                      className="w-full"
                      layout="fullWidth"
                      image={
                        i === 4
                          ? getImage(
                              item.acfCasePostTypeTeaserFields.teaserImage
                                .localFile.childImageSharp
                            )
                          : getImage(
                              item.featuredImage.node?.localFile
                                ?.childImageSharp
                            ) ||
                            getImage(
                              item.acfCasePostTypeTeaserFields.teaserImage
                                ?.localFile?.childImageSharp
                            )
                      }
                      alt=""
                    />
                  </div>
                  {/* )} */}
                  {/* {i === 2 && (
                    <BannerIframeWrap>
                      <iframe
                        title="Case animation"
                        width="100%"
                        height="100%"
                        src={`https://wpsk.hattenbergerpartner.de/banners/gClass_ani`}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                      ></iframe>
                    </BannerIframeWrap>
                  )} */}

                  <CardHeadline
                    className={`card-headline ${
                      item.acfCasePostTypeTeaserFields
                        .headlineHorizontalAlign === "center"
                        ? `text-center`
                        : item.acfCasePostTypeTeaserFields
                            .headlineHorizontalAlign === "right"
                        ? `text-right`
                        : ""
                    } ${
                      item.acfCasePostTypeTeaserFields.headlineVerticalAlign ===
                      "top"
                        ? `mb-auto`
                        : item.acfCasePostTypeTeaserFields
                            .headlineVerticalAlign === "bottom"
                        ? `mt-auto`
                        : "my-auto"
                    } ${
                      i === 2
                        ? `text-24 xs:text-46 sm:text-60 md:text-90 xl:text-120`
                        : `text-24 xl:text-32`
                    }`}
                  >
                    <span>
                      <span
                        className="relative"
                        style={{
                          color:
                            item.acfCasePostTypeTeaserFields.headlineColor ||
                            "#fff",
                        }}
                      >
                        {parse(
                          item.acfCasePostTypeTeaserFields.teaserHeadline ||
                            item.title
                        )}
                        {item.acfCasePostTypeTeaserFields.teaserSticker && (
                          <CardHeadlineSticker>
                            <img
                              src={getSrc(
                                item.acfCasePostTypeTeaserFields.teaserSticker
                                  .localFile
                              )}
                              alt=""
                            />
                          </CardHeadlineSticker>
                        )}
                      </span>
                    </span>
                    <div className="font-normal text-white normal-case card-subline xl:text-14 lg:text-12 text-14">
                      {parse(
                        item.acfCasePostTypeTeaserFields.teaserSubline ||
                          item.excerpt
                      )}
                    </div>
                  </CardHeadline>
                  <motion.img
                    className="absolute z-10 w-20 h-20 pointer-events-none case-sticker right-2 top-2"
                    variants={stopperAni}
                    src={stopperClick}
                    alt=""
                  />
                </CardImage>
              </Link>
            </Card>,
            i === 3 && (
              <ShowMoreCard className="showmore-card" key="showMoreCard">
                <ShowMoreCardHeadline>Mehr geiles Zeug?</ShowMoreCardHeadline>
                <Button
                  buttonText="Hier gehts zu weiteren Cases"
                  buttonHref="/cases/"
                  buttonClasses="bg-yellow text-black font-bold uppercase text-14 2xl:text-base font-primary w-auto block py-4 px-4 md:px-2 lg:px-4 text-center mt-5 mr-auto"
                  buttonStyle={{
                    boxShadow: "3px 3px 1px #000",
                  }}
                />
              </ShowMoreCard>
            ),
          ]
        })}
      </CaseGridContainer>
    </section>
  )
}

WpAcfCasesSectionBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfCasesSectionBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfCasesSectionBlock
