import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "@root/seo"
import WpAcfCasesSectionBlock from "@modules/WpAcfCasesSectionBlock"
import WpAcfColorfulCardsBlock from "@modules/WpAcfColorfulCardsBlock"
import SectionWelcomeHeader from "@modules/SectionWelcomeHeader"
import WpAcfStickerModuleBlock from "@modules/WpAcfStickerModuleBlock"
import WpAcfImageModuleBlock from "@modules/WpAcfImageModuleBlock"
import WpAcfHeadlineModuleBlock from "@modules/WpAcfHeadlineModuleBlock"
import WpAcfNewsTickerBlock from "@modules/WpAcfNewsTickerBlock"
import WpAcfPartnerLogosTickerBlock from "@modules/WpAcfPartnerLogosTickerBlock"
import WpAcfParallaxPartnerWallBlock from "../components/modules/WpAcfParallaxPartnerWallBlock"
import WpAcfAwardsModuleBlock from "@modules/WpAcfAwardsModuleBlock"
import WpAcfCasesModuleBlock from "@modules/WpAcfCasesModuleBlock"
import WpAcfCampaignModuleBlock from "@modules/WpAcfCampaignModuleBlock"
import WpAcfBlogAccordionBlock from "@modules/WpAcfBlogAccordionBlock"
import WpAcfQuoteModuleBlock from "@modules/WpAcfQuoteModuleBlock"
import WpAcfKartenModuleBlock from "@modules/WpAcfKartenModuleBlock"
import { useStateContext } from "@context/stateContext"

const IndexPage = () => {
  const stateContext = useStateContext()

  useEffect(() => {
    stateContext.dispatch({ action: "SET_MENUE_DARK" })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 28 }) {
        id
        slug
        title
        databaseId
        seo {
          metaDesc
          title
        }
        blocks {
          __typename
          ... on WpAcfCasesSectionBlock {
            attributes {
              anchor
            }
          }
          ... on WpAcfColorfulCardsBlock {
            attributes {
              anchor
              className
            }
            acfBlockColorfulCards {
              cards {
                slides {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 2000)
                    }
                  }
                }
                setAsSlider
                backgroundColor
                cardSize
                description
                caseDescription
                descriptionColor
                caseDescriptionColor
                headline
                headlineAlign
                headlineColor
                setAsBanner
                bannerUrl
                bannerFormat
                backgroundImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 2000)
                    }
                  }
                }
                melting
                link {
                  target
                  title
                  url
                }
                buttonAlignment
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1000)
                    }
                  }
                }
              }
            }
          }
          ... on WpAcfNewsTickerBlock {
            acfBlockNewsTicker {
              tickerItems {
                content
              }
              backgroundColor
              textColor
            }
            attributes {
              anchor
              className
            }
          }
          ... on WpAcfPartnerLogosTickerBlock {
            attributes {
              anchor
              className
            }
          }
          ... on WpAcfHeadlineModuleBlock {
            attributes {
              anchor
              className
            }
            acfHeadlineModuleBlock {
              backgroundColor
              headlineColor
              headline
              stickerPosition
              subline
              sublinePosition
              sublineColor
              headlineAsImage
              headlineImage {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(width: 2000)
                  }
                }
              }
              button {
                target
                title
                url
              }
              sticker {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpAcfImageModuleBlock {
            attributes {
              anchor
            }
            acfImageModuleBlock {
              size
              bannerUrl
              bannerFormat
              backgroundColor
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 2000)
                  }
                }
              }
            }
          }
          ... on WpAcfParallaxPartnerWallBlock {
            attributes {
              anchor
            }
            acfParallaxPartnerWall {
              cards {
                backgroundColor
                headline
                headlineColor
              }
              topImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              bottomImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpAcfStickerModuleBlock {
            attributes {
              anchor
            }
            acfBlockStickerSection {
              headline
              stickersContainer
              stickers {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800)
                    }
                  }
                }
              }
            }
          }
          ... on WpAcfAwardsModuleBlock {
            acfModuleAwards {
              headline
              backgroundColor
              color
              links {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1000)
                    }
                  }
                }
                link {
                  title
                  target
                  url
                }
              }
            }
            attributes {
              anchor
            }
          }
          ... on WpAcfCasesModuleBlock {
            acfModuleCasesModule {
              headline
            }
            attributes {
              anchor
            }
          }
          ... on WpAcfCampaignModuleBlock {
            acfModuleCampaign {
              headline
              cards {
                title
                text
                button {
                  title
                  target
                  url
                }
                animationType
              }
            }
            attributes {
              anchor
            }
          }
          ... on WpAcfBlogAccordionBlock {
            modulesBlogAccordion {
              theme
              items {
                title
                content
              }
            }
          }
          ... on WpAcfQuoteModuleBlock {
            acfModuleQuoteModule {
              theme
              name
              position
              text
              imagePosition
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }
          ... on WpAcfKartenModuleBlock {
            modulesKartenModule {
              theme
              columns
              headline
              items {
                title
                content
              }
            }
          }
        }
      }
    }
  `)
  const seo = data?.wpPage?.seo
  if (!data.wpPage?.blocks) return null
  return (
    <div className="bg-black">
      <Seo title={`${seo?.title || "Home"}`} description={`${seo?.metaDesc || ""}`} />
      <SectionWelcomeHeader />
      {data.wpPage.blocks.map((keyName, i) => {
        if (keyName.__typename === "WpAcfStickerModuleBlock") {
          return <WpAcfStickerModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfImageModuleBlock") {
          return <WpAcfImageModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfColorfulCardsBlock") {
          return <WpAcfColorfulCardsBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfNewsTickerBlock") {
          return <WpAcfNewsTickerBlock moduleData={keyName.acfBlockNewsTicker} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfPartnerLogosTickerBlock") {
          return <WpAcfPartnerLogosTickerBlock key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfHeadlineModuleBlock") {
          return <WpAcfHeadlineModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfCasesSectionBlock") {
          return <WpAcfCasesSectionBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfParallaxPartnerWallBlock") {
          return <WpAcfParallaxPartnerWallBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfAwardsModuleBlock") {
          return <WpAcfAwardsModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfCasesModuleBlock") {
          return <WpAcfCasesModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfCampaignModuleBlock") {
          return <WpAcfCampaignModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfBlogAccordionBlock") {
          return <WpAcfBlogAccordionBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfQuoteModuleBlock") {
          return <WpAcfQuoteModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else if (keyName.__typename === "WpAcfKartenModuleBlock") {
          return <WpAcfKartenModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
        } else {
          return ""
        }
      })}
    </div>
  )
}

export default IndexPage
